import React, { Suspense } from 'react'

const LazyDocumentDetailsWrapper = React.lazy(
  () => import('./components/DocumentComponentWrapper')
)

function DocumentDetailsFallBack() {
  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

function DocumentComponentWrapper() {
  return (
    <Suspense fallback={DocumentDetailsFallBack}>
      <LazyDocumentDetailsWrapper />
    </Suspense>
  )
}

export default function DocumentDetails() {
  return <DocumentComponentWrapper />
}
